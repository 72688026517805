import React, {useEffect, useState} from "react";
import {navigate} from "gatsby";

import Navbar from "../../../../components/navbar";
import Layout from "../../../../components/layout";

import * as Services from '../../../../services'
import productor from "../../../../images/descarga-reportes.svg";
import Seo from "../../../../components/seo";

function ReportsFarmFruitPage() {
    const [reports, setReports] = useState([]);
    const [farmDet, setFarmDetail] = useState(null);
    useEffect(() => {
        const farm = localStorage.getItem('productor') ? JSON.parse(localStorage.getItem('productor')): null;
        setFarmDetail(farm);
        Services.ReportsByProductorCultivos(farm?.id).then((response) => {
            setReports(response.reportes)
        }).catch(error => {
            console.log(error)
        })
    }, [])
    const goToURL = (url, report) => {
        localStorage.setItem('reporte', JSON.stringify(report))
        navigate(url);
    }
    return(
        <Layout>
            <Navbar />
            <div className="dashInfo">
                <div className="titleGeneral h-1/4">
                    <div className="font-bold text-white text-center pt-8 pb-12">
                        Reportes de Productor <br />
                        {farmDet?.name}
                    </div>
                </div>
                <div className="bg-white pt-6 px-6 overflow-y-auto h-3/4 max-h-3/4 rounded-t-3xl -mt-8">
                    {
                        reports && reports.map((report) => (
                            <button className="card mb-4 bg-yellow-300" onClick={() => goToURL('cultivo', report)} key={report.id}>
                                <img src={productor} alt="productor" width={80} height={80} className="mr-4" />
                                <div className="font-bold font-greenup-body text-green-gu text-center">
                                    Reporte { report.id } del cultivo { report.cultivo }
                                </div>
                            </button>
                        ))
                    }
                    <button
                        onClick={() => navigate('/dashboard/add-evaluacion', { state: { productor: farmDet, reportes: undefined } })}
                        className="inline-flex items-center justify-center p-3 w-full bg-green-light-gu rounded-full font-bold text-white focus:ring-2 focus:ring-green-light-gu focus:bg-green-gu"
                    >
                        Agregar reporte
                    </button>
                </div>
            </div>
        </Layout>
    )
}

export const Head = () => <Seo title="Reportes del productor" />

export default ReportsFarmFruitPage
